export class MedicalRecordsMapping {
  public static ALL_MEDICAL_RECORDS = {
    "folders[].box": "medicalRecords[].box",
    "folders[].captured": "medicalRecords[].captured",
    "folders[].code": "medicalRecords[].code",
    "folders[].date_in": "medicalRecords[].ricover_date",
    "folders[].date_out": "medicalRecords[].discharge_date",
    "folders[].exit_department": "medicalRecords[].discharge_department",
    "folders[].id": "medicalRecords[].id",
    "folders[].num_folder": "medicalRecords[].folder_number",
    "folders[].order_present": "medicalRecords[].order_present",
    "folders[].orphan_code": "medicalRecords[].orphan_code",
    "folders[].orphan_date": "medicalRecords[].orphan_date",
    "folders[].orphan_pages": "medicalRecords[].orphan_pages",
    "folders[].orphan_present": "medicalRecords[].orphan_present",
    "folders[].pages": "medicalRecords[].pages",
    "folders[].position": "medicalRecords[].position",
    "folders[].pretty_status": "medicalRecords[].pretty_status",
    "folders[].scanned": "medicalRecords[].has_pdf",
    "folders[].status": "medicalRecords[].status",
    "folders[].status_color": "medicalRecords[].status_color",
    "folders[].to_review": "medicalRecords[].to_review",
    "folders[].hospital.name": "medicalRecords[].hospital",
    "folders[].year": "medicalRecords[].year",
    "folders[].link_diagnostics": "medicalRecords[].link_diagnostics",
    "folders[].typology": "medicalRecords[].type",
    "pagination.current_page": "pageIndex",
    "pagination.per_page": "pageSize",
    "pagination.total_pages": "total",
  };

  public static MEDICAL_RECORD_DETAIL = {
    "folder.captured": "captured",
    "folder.code": "code",
    "folder.date_in": "ricover_date",
    "folder.date_out": "discharge_date",
    "folder.exit_department": "discharge_department",
    "folder.id": "id",
    "folder.num_folder": "folder_number",
    "folder.order_present": "order_present",
    "folder.orphan_code": "orphan_code",
    "folder.orphan_date": "orphan_date",
    "folder.orphan_pages": "orphan_pages",
    "folder.orphan_present": "orphan_present",
    "folder.pages": "pages",
    "folder.position": "position",
    "folder.pretty_status": "pretty_status",
    "folder.scanned": "has_pdf",
    "folder.status": "status",
    "folder.status_color": "status_color",
    "folder.to_review": "to_review",
    "folder.hospital.name": "hospital",
    "folder.year": "year",
    "folder.typology": "type",
    "folder.link_diagnostics": "link_diagnostics",
    pdf_url: "pdf_url",
    images_url: "images_url",
  };
}
