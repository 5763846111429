import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable} from "rxjs/Observable";
import {Folder} from "./folder.model";
import { FolderService } from "./folder.service";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {catchError, finalize} from "rxjs/operators";
import {of} from "rxjs/observable/of";



export class FolderDataSource implements DataSource<Folder> {

    private foldersSubject = new BehaviorSubject<Folder[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public total: number;

    constructor(private folderService: FolderService) {

    }

    set data(v: Folder[]) { this.foldersSubject.next(v); }
    get data(): Folder[] { return this.foldersSubject.value; }

    //courseId:number,filter:string,sortDirection:string,pageIndex:number,pageSize:number
    loadFolders(folder:Folder,  pageIndex = 0, pageSize = 10, order_by:string, direction:string) {

        this.loadingSubject.next(true);

        this.folderService.list(folder, pageIndex, pageSize, order_by, direction).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(res => {
                this.foldersSubject.next(res["folders"]);
                this.total = res["total"];
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<Folder[]> {
        return this.foldersSubject.asObservable();
      }

    disconnect(collectionViewer: CollectionViewer): void {
        this.foldersSubject.complete();
        this.loadingSubject.complete();
    }


}
