import { Pagination } from "./pagination.model";

export class MedicalRecord {
  id: number;
  hospital: string;
  code: string;
  folder_number: string;
  year: number;
  ricover_date: Date;
  discharge_date: Date;
  has_pdf: boolean;
  pages?: number;
  type?: string;
  discharge_department?: string;
  pdf_url?: string;
  images_url?: Array<string>;
  link_diagnostics?: string;
}

export class MedicalRecordsResponse extends Pagination {
  medicalRecords: Array<MedicalRecord>;
}
