import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MedicalRecordsFiltersInterface } from "src/app/interfaces/medical-records-filter.interface";
import {
  MedicalRecord,
  MedicalRecordsResponse,
} from "src/app/models/medical-record.model";
import { Mapper } from "src/app/utils/mapper";
import { environment } from "src/environments/environment";

@Injectable()
export class MedicalRecordsService {
  public main_path: string = `${environment.API_ENDPOINT}/v1/patients/folders`;
  constructor(private http: HttpClient) {}

  getMedicalRecords(
    filters?: MedicalRecordsFiltersInterface,
    mapping?: any
  ): Observable<MedicalRecordsResponse> {
    return this.http.get(this.main_path, { params: <any>filters }).pipe(
      map((res: any) => {
        if (mapping && res) {
          return Mapper.map<any>(res, mapping);
        }
        return res;
      }),
      catchError((x) => {
        return of({});
      })
    );
  }

  getMedicalRecordDetail(id: number, mapping?: any): Observable<MedicalRecord> {
    const _options: any = {};
    return this.http.get(`${this.main_path}/${id}`, _options).pipe(
      map((res: any) => {
        if (mapping && res) {
          return Mapper.map<any>(res, mapping);
        }
        return res;
      }),
      catchError((x) => {
        return of({});
      })
    );
  }

  downloadPDF(id: number): Observable<Object> {
    const _options: any = {};
    const path = "v1/hospitals/folders/download_folder";
    return this.http.get(path, _options).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((x) => {
        return of({});
      })
    );
  }
}
